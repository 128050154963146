<template>
  <VCard class="home-location text-xs-left elevation-0">
    <div
      class="loc-image"
      :style="{
        'background-image': `url(${thumb})`,
      }"
      :alt="`Image for ${location.title}`"
      @click="$router.push({ path: `/locations/${location.slug}` })"
    ></div>
    <!-- <VContainer> -->
    <VLayout class="location--content pa-3" column>
      <VFlex grow pt-0>
        <div class="location--name header-1 pb-3 mb-3 d-inline-block"
          ><RouterLink :to="{ path: `/locations/${location.slug}` }">
            {{ location.title }}
          </RouterLink></div
        >

        <p v-if="location.status === 'coming_soon'" class="r-title">{{
          location.description
        }}</p>
        <div v-else class="location--info r-title">
          <div class="location--address">
            <div v-if="getContactDetail('street_1')">{{
              getContactDetail('street_1')
            }}</div>
            <div v-if="getContactDetail('street_2')">{{
              getContactDetail('street_2')
            }}</div>
            <div>{{
              `${getContactDetail('city')}, ${getContactDetail('state')}`
            }}</div>
            <a
              v-if="getContactDetail('phone_number')"
              class="location--phone-link"
              :href="`tel:${formatPhone(getContactDetail('phone_number'))}`"
              >{{ `\n${getContactDetail('phone_number')}` }}</a
            >
          </div>
          <div class="r-title mt-3 mb-0 location--hours">{{
            location.hours_for_humans
          }}</div>
        </div>
      </VFlex>
      <VFlex shrink>
        <!-- TODO (ESS) : figure out a way to use global colors that doesn't involve css modules -->
        <template v-if="location.status !== 'coming_soon'">
          <BaseButtonOutline
            outline-color="#CA6FDA"
            line-weight="1"
            class="ml-0"
            :to="{ path: `/locations/${location.slug}` }"
          >
            View Menu
          </BaseButtonOutline>
          <BaseButtonOutline
            outline-color="#CA6FDA"
            line-weight="1"
            class="ml-0"
            @click="openOrderDialog"
          >
            Order
          </BaseButtonOutline>
        </template>
      </VFlex>
    </VLayout>
    <!-- </VContainer> -->
  </VCard>
</template>

<script>
import getSiteAsset from '@utils/get-site-asset'
import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

export default {
  name: 'BubsLocationCard',
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteId']),
    ...mapFields('nav', ['isOrderDialogOpen', 'orderDialogSelectedLocation']),
    thumb() {
      return getSiteAsset(`location-${this.location.id}-thumb.jpg`)
    }, // thumb
  },
  methods: {
    formatPhone,
    getContactDetail(key) {
      return getContactDetail(this.location, key)
    }, // getContactDetail
    openOrderDialog() {
      this.orderDialogSelectedLocation = this.location
      this.isOrderDialogOpen = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@design';

.location {
  &--content {
    min-height: 20vw;
  }
  &--info {
    .r-title {
      // TODO (ESS) : don't. even
      line-height: 1.5 !important;

      @media only screen and (max-width: 414px) {
        font-size: 18px !important;
      }
    }
  }
  &--name {
    width: 80%;
    border-bottom: 3px solid $color-bubs-red;
    a {
      text-decoration: none;
    }
  }
  &--phone-link {
    text-decoration: none;
  }
  &--hours {
    height: 2.5rem;
    white-space: pre;
  }
}
.loc-image {
  width: 100%;
  height: 12vw;
  min-height: 150px;
  color: white;
  cursor: pointer;
  background-color: $color-bubs-wax-paper;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>
